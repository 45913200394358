import React, { useContext } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"

import CartContext from "../../context/cart/cart-context"

import BlurLoader from "../generic/loaders/blur-loader"
import { getProperty } from "../../lib/utils"

const OrderStatus = ({ orderData = {} }) => {
  const { branches } = useContext(CartContext)

  // Fallback message
  let orderMessage = "Your order has been placed"

  // Generic order messages for most payment methods
  if (orderData.status == "on-hold") {
    orderMessage =
      "Your order has been placed but is awaiting payment. Please click the button labeled Try Again to attempt payment now"
  } else if (
    orderData.status == "processing" ||
    orderData.status == "completed"
  ) {
    orderMessage =
      "Thank you for order! Your payment has been received and your order is being processed"
  } else if (orderData.status == "failed") {
    orderMessage =
      "Something went wrong while processing your payment. Please click the button labeled Try Again to attempt payment now"
  }

  // Specific order message that depend on payment method
  // This will override the generic ones above
  if (orderData.payment_method == "bacs") {
    if (orderData.status == "on-hold") {
      orderMessage =
        "Thank you for order! Your order is on hold awaiting bank transfer"
    }
  }

  // Get EFT account details
  const accountDetails = getProperty(orderData, [
    "payment_method_account_details",
    0,
  ])

  // Get Special Delivery Instruction meta data
  const deliveryInstruction = orderData?.meta_data?.find?.(
    (met) => met?.key == "delivery_instruction"
  )?.value

  // We have something basic here just to be readable.
  return (
    <BlurLoader loading={!orderData.id} message="Fetching">
      <StyledContainer>
        <h2>Order Details (Order ID: {orderData.order_number})</h2>
        <h3>{orderMessage}</h3>
        <OrderInfoItem>
          {"Payment Method: "}
          <span>{orderData.payment_method_title}</span>
        </OrderInfoItem>
        <OrderInfoItem>
          {"Payment Status: "}
          <span>{orderData.status}</span>
        </OrderInfoItem>
        <OrderInfoItem>
          {"Contact Details: "}
          <span>
            {orderData.billing && orderData.billing.first_name}{" "}
            {orderData.billing && orderData.billing.last_name}
          </span>
        </OrderInfoItem>
        {orderData?.shipping_data?.method_id?.includes("local_pickup") ? (
          <OrderInfoItem>
            {"Collection Address: "}
            {branches && branches.length > 0 && (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: branches[0]?.contact_details?.name,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: branches[0]?.address?.street_address,
                  }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: branches[0]?.contact_details?.contact_email,
                  }}
                />
                <div>
                  {branches[0]?.contact_details?.telephones?.map((num) => (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${num?.telephone_label}: ${num?.telephone_number}`,
                      }}
                    />
                  ))}
                </div>
              </>
            )}
          </OrderInfoItem>
        ) : (
          <OrderInfoItem>
            {"Delivery Address: "}
            <div>{orderData.shipping && orderData.shipping.address_1}</div>
            <div>{orderData.shipping && orderData.shipping.address_2}</div>
            <div>{orderData.shipping && orderData.shipping.city}</div>
            <div>{orderData.shipping && orderData.shipping.postcode}</div>
            <div>{orderData.shipping && orderData.shipping.state}</div>
            {deliveryInstruction ? "Delivery Instruction: " : null}
            {deliveryInstruction ? (
              <div className="deliveryNote">{deliveryInstruction}</div>
            ) : null}
          </OrderInfoItem>
        )}
        {orderData.payment_method != "bacs" ? null : (
          <OrderInfoDescription>
            {orderData.payment_method_description}
            <OrderInfoDescription>
              <OrderInfoItem>
                {"Account Name: "}
                <span>{accountDetails.account_name}</span>
              </OrderInfoItem>
              <OrderInfoItem>
                {"Bank Name: "}
                <span>{accountDetails.bank_name}</span>
              </OrderInfoItem>
              <OrderInfoItem>
                {"Account Number: "}
                <span>{accountDetails.account_number}</span>
              </OrderInfoItem>
              <OrderInfoItem>
                {"Branch Number: "}
                <span>{accountDetails.sort_code}</span>
              </OrderInfoItem>
              <OrderInfoItem>
                {"Reference: "}
                <span>{orderData.order_number}</span>
              </OrderInfoItem>
            </OrderInfoDescription>
          </OrderInfoDescription>
        )}
      </StyledContainer>
    </BlurLoader>
  )
}

export default OrderStatus

// ===============
//     STYLES
// ===============
const BoldTitle = styled.span`
  font-weight: 600;
`
const StyledContainer = styled.div`
  h2 {
    margin-bottom: 2rem;
  }
  h3 {
    margin-bottom: 1.5rem;
  }
`
const OrderInfoItem = styled.div`
  margin: 10px 0;
  font-weight: bold;
  span {
    font-weight: normal;
  }
  div {
    font-weight: normal;
    line-height: 1.5;
  }
  .deliveryNote {
    font-style: italic;
  }
`
const OrderInfoDescription = styled.div`
  margin: 20px 0;
`
